import React, { Component } from 'react'
import { MDBContainer, MDBRow, MDBCol, toast, ToastContainer } from 'mdbreact'
import { Zoom } from 'react-toastify'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import axios from 'axios'
import { Link } from 'gatsby'

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, 'Name must be 2 characters minimum')
    .required('Name is required'),
  email: Yup.string()
    .email('Invalid email address format')
    .required('Email is required'),
  company: Yup.string()
    .min(2, 'Company name must be 2 characters minimum')
    .required('Company is required'),
  country: Yup.string()
    .min(2, 'Country must be 2 characters minimum')
    .required('Country is required'),
  priority: Yup.string()
    .required('Priority is required'), 
  product: Yup.string()
    .required('Product is required'),     
  version: Yup.string()
    .min(2, 'Version must be 2 characters minimum')
    .required('Version is required'),
  platform: Yup.string()
    .min(2, 'Platform must be 2 characters minimum')
    .required('Platform is required'),
  osversion: Yup.string()
    .required('OS Version is required'),
  title: Yup.string()
    .required('Issue title is required'),
  description: Yup.string()
    .required('Description is required'),                             

  subscribe: Yup.bool().oneOf([true], 'Terms and conditions need to be accepted'),
})

// const formUrl = 'https://script.google.com/macros/s/AKfycby1ZjnbwMuRo-GoRdjZHsczAIhvdbxWktziihuebR0v_9j6qysus7Wk/exec'

const formUrl = 'https://script.google.com/macros/s/AKfycbyO4Zlnpi0OjuwLLLl5lHSSQE_uAxrMU2L5Ry_EjaGMgqh8NCGa-sCxTu9ij52n3-eK/exec'

const messageConfirm = () => {
  return toast.info("Thank you for contacting us.  We'll be in touch shortly!", { closeButton: false, position: toast.POSITION.TOP_CENTER, transition: Zoom })
}

class ContactMain extends Component {
  constructor(props, ...args) {
    super(props, ...args)
    this.state = {
      callback: 'not fired',
      value: null,
      load: false,
      expired: 'false',
    }
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({ 
        load: true, 
        pageUrl: location.href 
      })
    })
  }

  handleChange = value => {
    this.setState({ value })
  }

  asyncScriptOnLoad = () => {
    this.setState({ callback: 'called!' })
  }

  render() {
    const { value, callback, load, expired } = this.state || {}

    return (
      <MDBContainer>
        <MDBRow className="justify-content-center">
          <MDBCol md="6" className="md-0 mb-5">
            <Formik
              initialValues={{
                name: '',
                email: '',
                company: '',
                country: '',             
                priority: '',
                product: '', 
                version: '',
                platform: '',
                osversion: '',
                cicsversion: '',                                                
                title: '',
                description: '',
                subscribe: false,
              }}
              validationSchema={validationSchema}
              onSubmit={(values, {setSubmitting, resetForm}) => {
                /* alert('Form is validated! Submitting the form...') */
                let formData = {
                  Name: values.name,
                  Email: values.email,
                  Company: values.company,
                  Country: values.country,                
                  Priority: values.priority,
                  Product: values.product,
                  Version: values.version,
                  Platform: values.platform,
                  Osversion: values.osversion,
                  Cicsversion: values.cicsversion,                                                      
                  Title: values.title,
                  Description: values.description,
                  Subscribe: values.subscribe,
                }
                try {
                  const response = axios({
                      method: 'get',
                      url: `${formUrl}?timestamp=&url=${encodeURIComponent(this.state.pageUrl)}&name=${encodeURIComponent(values.name)}&email=${encodeURIComponent(values.email)}&company=${encodeURIComponent(values.company)}&country=${encodeURIComponent(values.country)}&priority=${encodeURIComponent(values.priority)}&product=${encodeURIComponent(values.product)}&productversion=${encodeURIComponent(values.version)}&platform=${encodeURIComponent(values.platform)}&osversion=${encodeURIComponent(values.osversion)}&cicsversion=${encodeURIComponent(values.cicsversion)}&title=${encodeURIComponent(values.title)}&description=${encodeURIComponent(values.description)}&subscribe=${encodeURIComponent(values.subscribe)}`
                    })
                      //console.log(response)
                      this.setState({values: '', expired: 'true'})
                      resetForm()
                      messageConfirm()
                    } catch (e) {
                      console.log(`Axios request failed: ${e}`)
                    }
                  setSubmitting(false)
                }}
            >
              {({ touched, errors, values, isSubmitting }) => (
                <Form name="contact" method="post" action="" data-netlify="true" data-netlify-honeypot="bot-field" >
                  <input type="hidden" name="bot-field" />
                  <input type="hidden" name="form-name" value="contact" />
                  <MDBRow>
                    <MDBCol md="12">
                      <div className="mb-4 grey-text font-w-600 text-medium">
                        <label htmlFor="name"> Name <span style={{ color: 'red' }}>*</span> </label>
                        <Field type="text" id="name "name="name" aria-label="name" value={values.name} placeholder="Your full name" className={`form-control ${ touched.name && errors.name ? 'is-invalid' : '' }`} />
                        <ErrorMessage component="div" name="name" className="invalid-feedback" />
                      </div>
                    </MDBCol>
                  </MDBRow>

                  <MDBRow>
                    <MDBCol md="12">
                      <div className="mb-4 grey-text font-w-600 text-medium">
                      <label htmlFor="email"> Email <span style={{ color: 'red' }}>*</span> </label>
                        <Field type="text" id="email" name="email" aria-label="email" value={values.email} placeholder="Your email address" className={`form-control ${ touched.email && errors.email ? 'is-invalid' : '' }`} />
                        <ErrorMessage component="div" name="email" className="invalid-feedback" />
                      </div>
                    </MDBCol>
                  </MDBRow>

                  <hr />

                  <MDBRow>
                    <MDBCol lg="6" md="12">
                      <div className="mb-4 grey-text font-w-600 text-medium">
                        <label htmlFor="company"> Company name <span style={{ color: 'red' }}>*</span> </label>
                        <Field type="text" id="company" name="company" aria-label="company" value={values.company} placeholder="Your company name" className={`form-control ${ touched.company && errors.company ? 'is-invalid' : '' }`} />
                        <ErrorMessage component="div" name="company" className="invalid-feedback" />
                      </div>
                    </MDBCol>

                    <MDBCol lg="6" md="12">
                      <div className="mb-4 grey-text font-w-600 text-medium">
                        <label htmlFor="country"> Country <span style={{ color: 'red' }}>*</span> </label>
                        <Field type="text" id="country" name="country" aria-label="country" value={values.country} placeholder="Your country of residence" className={`form-control ${ touched.country && errors.country ? 'is-invalid' : '' }`} />
                        <ErrorMessage component="div" name="country" className="invalid-feedback" />
                      </div>
                    </MDBCol>                    
                  </MDBRow>              

                  <hr />          

                  <MDBRow>
                    <MDBCol lg="6" md="12">
                      <div className="mb-4 grey-text font-w-600 text-medium">
                        <label htmlFor="priority"> Priority <span style={{ color: 'red' }}>*</span> </label>
                        <Field
                          type="priority"
                          name="priority"
                          id="priority"
                          as="select"
                          value={values.priority}
                          placeholder="Please select an option"
                          className={`form-control ${
                            touched.priority && errors.priority
                              ? 'is-invalid'
                              : ''
                          }`}
                        >
                          <option value="" label="Please select a value">
                            {' '}
                          </option>
                          <option value="Critical - system down" label="Critical - system down">
                            Critical - system down
                          </option>
                          <option value="High" label="High">
                            High
                          </option>
                          <option value="Medium" label="Medium">
                            Medium
                          </option>
                          <option value="Low" label="Low">
                            Low
                          </option>
                        </Field>
                        <ErrorMessage
                          component="div"
                          name="priority"
                          className="invalid-feedback"
                        />
                      </div>
                    </MDBCol>

                    <MDBCol lg="6" md="12">
                      <div className="mb-4 grey-text font-w-600 text-medium">
                        <label htmlFor="product"> Product <span style={{ color: 'red' }}>*</span> </label>
                        <Field
                          type="product"
                          name="product"
                          id="product"
                          as="select"
                          value={values.product}
                          placeholder="Please select an option"
                          className={`form-control ${
                            touched.product && errors.product
                              ? 'is-invalid'
                              : ''
                          }`}
                        >
                          <option value="" label="Please select a value">
                            {' '}
                          </option>
                          <option value="AUTOMON®/BATCH" label="AUTOMON®/BATCH">
                            AUTOMON®/BATCH
                          </option>
                          <option value="AUTOMON®/CICS" label="AUTOMON®/CICS">
                            AUTOMON®/CICS
                          </option>
                          <option value="AUTOMON®/LogAnalyzer" label="AUTOMON®/LogAnalyzer">
                            AUTOMON®/LogAnalyzer
                          </option>
                          <option value="AUTOMON®/RDO" label="AUTOMON®/RDO">
                            AUTOMON®/RDO
                          </option>
                          <option value="AUTOMON®/TCPIP" label="AUTOMON®/TCPIP">
                            AUTOMON®/TCPIP
                          </option>
                          <option value="AUTOMON®/VTAM" label="AUTOMON®/VTAM">
                            AUTOMON®/VTAM
                          </option>
                          <option value="CARTS" label="CARTS">
                            CARTS
                          </option>
                          <option value="CICS-FCTD" label="CICS-FCTD">
                            CICS-FCTD
                          </option>
                          <option value="CICS-FileServ" label="CICS-FileServ">
                            CICS-FileServ
                          </option>
                          <option value="CICS-Juggler®" label="CICS-Juggler®">
                            CICS-Juggler®
                          </option>
                          <option value="CICS-Windows" label="CICS-Windows">
                            CICS-Windows
                          </option>
                          <option value="Comet" label="Comet">
                            Comet
                          </option>
                          <option value="Help-Windows" label="Help-Windows">
                            Help-Windows
                          </option>
                          <option value="PIE®/CICS" label="PIE®/CICS">
                            PIE®/CICS
                          </option>
                          <option value="PIE®/Rexx" label="PIE®/Rexx">
                            PIE®/Rexx
                          </option>
                          <option value="PIE®/TSO" label="PIE®/TSO">
                            PIE®/TSO
                          </option>
                          <option value="SSA" label="SSA">
                            SSA
                          </option>
                          <option value="SSR" label="SSR">
                            SSR
                          </option>
                          <option value="SUPERMON®/CICS" label="SUPERMON®/CICS">
                            SUPERMON®/CICS
                          </option>
                          <option value="TapeSaver®" label="TapeSaver®">
                            TapeSaver®
                          </option>
                          <option value="UCCF®/Batch" label="UCCF®/Batch">
                            UCCF®/Batch
                          </option>
                          <option value="UCCF®/Batch/Server" label="UCCF®/Batch/Server">
                            UCCF®/Batch/Server
                          </option>
                          <option value="UNICOM®/CENTRAL®" label="UNICOM®/CENTRAL®">
                            UNICOM®/CENTRAL®
                          </option>
                          <option value="VTAM-Windows" label="VTAM-Windows">
                            VTAM-Windows
                          </option>
                          <option value="z/Ware" label="z/Ware">
                            z/Ware
                          </option>
                        </Field>
                        <ErrorMessage
                          component="div"
                          name="product"
                          className="invalid-feedback"
                        />
                      </div>
                    </MDBCol>
                  </MDBRow> 

                  <MDBRow>
                    <MDBCol lg="6" md="12">
                      <div className="mb-4 grey-text font-w-600 text-medium">
                        <label htmlFor="version"> Product version <span style={{ color: 'red' }}>*</span> </label>
                        <Field type="text" id="version" name="version" aria-label="version" value={values.version} placeholder="Your product version" className={`form-control ${ touched.version && errors.version ? 'is-invalid' : '' }`} />
                        <ErrorMessage component="div" name="version" className="invalid-feedback" />
                      </div>
                    </MDBCol>

                    <MDBCol lg="6" md="12">
                      <div className="mb-4 grey-text font-w-600 text-medium">
                        <label htmlFor="platform"> Platform <span style={{ color: 'red' }}>*</span> </label>
                        <Field type="text" id="platform" name="platform" aria-label="platform" value={values.platform} placeholder="Your platform" className={`form-control ${ touched.platform && errors.platform ? 'is-invalid' : '' }`} />
                        <ErrorMessage component="div" name="platform" className="invalid-feedback" />
                      </div>
                    </MDBCol>                    
                  </MDBRow>

                  <MDBRow>
                    <MDBCol lg="6" md="12">
                      <div className="mb-4 grey-text font-w-600 text-medium">
                        <label htmlFor="osversion"> OS version <span style={{ color: 'red' }}>*</span> </label>
                        <Field type="text" id="osversion" name="osversion" aria-label="osversion" value={values.osversion} placeholder="Your OS version" className={`form-control ${ touched.osversion && errors.osversion ? 'is-invalid' : '' }`} />
                        <ErrorMessage component="div" name="osversion" className="invalid-feedback" />
                      </div>
                    </MDBCol>

                    <MDBCol lg="6" md="12">
                      <div className="mb-4 grey-text font-w-600 text-medium">
                        <label htmlFor="cicsversion"> CICS version (if applicable) </label>
                        <Field type="text" id="platform" name="cicsversion" aria-label="cicsversion" value={values.cicsversion} placeholder="Your CICS version" className={`form-control ${ touched.cicsversion && errors.cicsversion ? 'is-invalid' : '' }`} />
                        <ErrorMessage component="div" name="cicsversion" className="invalid-feedback" />
                      </div>
                    </MDBCol>                    
                  </MDBRow>

                  <hr />

                 <MDBRow>
                    <MDBCol md="12">
                      <div className="mb-4 grey-text font-w-600 text-medium">
                      <label htmlFor="title"> Issue title <span style={{ color: 'red' }}>*</span> </label>
                        <Field type="text" id="title" name="title" aria-label="title" value={values.title} placeholder="Your issue title" className={`form-control ${ touched.title && errors.title ? 'is-invalid' : '' }`} />
                        <ErrorMessage component="div" name="title" className="invalid-feedback" />
                      </div>
                    </MDBCol>
                  </MDBRow>                  

                  <MDBRow>
                    <MDBCol md="12">
                      <div className="mb-4 grey-text font-w-600 text-medium">
                        <label htmlFor="description">Issue description <span style={{ color: 'red' }}>*</span> </label>
                        <Field type="textarea" component="textarea" name="description" aria-label="description" value={values.description} placeholder="Please describe your issue" className={`form-control ${ touched.description && errors.description ? 'is-invalid' : '' }`} />
                        <ErrorMessage component="div" name="description" className="invalid-feedback" />
                      </div>
                    </MDBCol>
                  </MDBRow>

                  <MDBRow>
                    <MDBCol md="12">
                      <div className="mt-3 mb-4 grey-text font-w-400 text-small">
                        To receive occasional updates about UNICOM Systems Inc's
                        products, services, news and events, please tick the box
                        below. You can unsubscribe at any time.
                      </div>
                    </MDBCol>
                  </MDBRow>

                  <MDBRow>
                    <MDBCol md="12">
                      <div className="form-group form-check mt-3 mb-4 grey-text font-w-400 text-small">
                        <Field
                          type="checkbox"
                          id="subscribe"
                          name="subscribe"
                          aria-label="subscribe"
                          className={`form-check-input ${
                            touched.subscribe && errors.subscribe
                              ? 'is-invalid'
                              : ''
                          }`}
                        />
                        <label htmlFor="subscribe">
                          Yes, I would like to receive communications from
                          UNICOM Systems, Inc.
                        </label>
                      </div>
                    </MDBCol>
                  </MDBRow>

                  <MDBRow>
                    <MDBCol md="12">
                      <div className="mb-4 grey-text font-w-400 text-small">
                        For details on how your data is stored and used, please
                        visit our{' '}
                        <Link to="/privacy-statement/" className="effect">
                          Privacy Statement
                        </Link>
                        .
                      </div>
                    </MDBCol>
                  </MDBRow>

                  <MDBRow>
                    <MDBCol md="12" className="mb-4">
                      <div style={{ paddingTop: '1rem' }}>
                        <button
                          type="submit"
                          className="mt-4 btn btn-medium btn-unicom"
                          style={{ display: 'block' }}
                          disabled={ isSubmitting ? true : false }
                        >
                          {isSubmitting ? 'Please wait...' : 'Submit'}
                        </button>
                        <ToastContainer
                          transition={Zoom}
                          hideProgressBar={true}
                          newestOnTop={true}
                          autoClose={5000}
                        />
                      </div>
                    </MDBCol>
                  </MDBRow>
                </Form>
              )}
            </Formik>
          </MDBCol>

     
        </MDBRow>
      </MDBContainer>
    )
  }
}

export default ContactMain
